export enum RolesEnum {
    AP4500001_Accounting_Setup = "AP4500001-Accounting-Setup",
    AP4500001_Conciliation_Credit_Card_Superuser = "AP4500001-Conciliation-Credit-Card-Superuser",
    AP4500001_Conciliation_Credit_Card_User_Mng_read_only = "AP4500001-Conciliation-Credit-Card-User-Mng-read-only",
    AP4500001_Conciliation_Credit_Card_Users_read_only = "AP4500001-Conciliation-Credit-Card-Users-read-only",
    AP4500001_Credit_card_Bill_Read_only = "AP4500001-Credit-card-Bill-Read-only",
    AP4500001_Credit_Card_Bill_Superuser = "AP4500001-Credit-Card-Bill-Superuser",
    AP4500001_Credit_card_Manage_superuser = "AP4500001-Credit-card-Manage-superuser",
    AP4500001_Finance_Flags_superuser = "AP4500001-Finance-Flags-superuser",
    AP4500001_Finance_FollowUp_read_only = "AP4500001-Finance-FollowUp-read-only",
    AP4500001_Setup_Finance = "AP4500001-Setup-Finance",
    AP4500001_Setup_Users_Read_Only = "AP4500001-Setup-Users-Read-Only",
    AP4500001_Travel_super_user = "AP4500001-Travel-super-user",
    AP4500001_Users_Read_Only = "AP4500001-Users-Read-Only",
}

// AP4500001_Users_Read_Only é Role default para acesso em telas sem dados sensíveis ou designadas para consultas de usuários