import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserSessionInfo } from '../_models/UserSessionInfo';

@Injectable({
    providedIn: 'root', // Disponível para toda a aplicação
})

export class UserSessionInfoService {

    private userSessionInfoSubject = new BehaviorSubject<UserSessionInfo | null>(null); // Inicializa como null
    public userSessionInfo$: Observable<UserSessionInfo | null> = this.userSessionInfoSubject.asObservable();

    // Método para definir o usuário
    setUser(user: UserSessionInfo): void {
        this.userSessionInfoSubject.next(user);
    }

    // Método para obter o valor atual do usuário
    getUser(): UserSessionInfo | null {
        return this.userSessionInfoSubject.value;
    }

}